type ErrorType = {[key: string]: {type?: string; message: string}}

type NotificationProps = {
  idNotification: number
  Notification_Name: string
  Email_List: string
  SMS_List: string
}

type State = {
  message: string
  expiresIn: number
  email: string
  phone: string
  isDirty: boolean
  submitting: boolean
  errors?: ErrorType
  notification: NotificationProps
  validEmail?: boolean
  ordinalNumberEmail?: string
  validPhone?: boolean
  ordinalNumberPhone?: string
}

export const initialState: State = {
  message: '',
  expiresIn: 1,
  email: '',
  phone: '',
  isDirty: false,
  submitting: false,
  errors: {},
  notification: {
    idNotification: 0,
    Notification_Name: '',
    Email_List: '',
    SMS_List: '',
  },
}

type Action = {
  type: 'setState'
  data: Partial<State>
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setState':
      return {
        ...state,
        ...action.data,
      }

    default:
      console.error('invalid reducer action', action)
      break
  }
  return state
}
