import * as React from 'react'
import {useParams} from 'react-router'
import {ascendColors} from 'common/GlobalStyles'
import {initialState, pageDetails, reducer} from './promotionalPageHelpers'
import {
  Container,
  ModalBody,
  PromoButtonRow,
  PromoContent,
  SendButtonRow,
  SentContent,
  StyledButton,
  Tile,
  TileRow,
} from './promotionalPageStyles'
import InputWithLabel from '../InputWithLabel'
import AlertModal from '../AlertModal'
import RequestSent from 'images/promotional/RequestSent.svg'
import Modal from 'common/Modal'
import TextAreaWithLabel from '../TextAreaWithLabel'
import {Typography} from '../StyledComponents'

type Props = {
  propsPageType?:
    | 'driverScorecard'
    | 'engineDiagnostics'
    | 'fuelCards'
    | 'maintenance'
    | 'inspections'
    | 'eld'
}

const PromotionalPage: React.FC<Props> = ({propsPageType}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {pageType} = useParams()
  const pageName = pageType || propsPageType
  const page = pageDetails.find(page => page.id === pageName)

  const checkForm = () => {
    if (state.firstName === '' || state.lastName === '' || state.email === '') {
      return false
    }
    return true
  }

  const onSend = () => {
    if (checkForm()) {
      dispatch({
        type: 'setState',
        data: {
          sentMessage: true,
          firstName: '',
          lastName: '',
          email: '',
          note: '',
        },
      })
    } else {
      dispatch({
        type: 'setState',
        data: {
          showErrorDialog: true,
        },
      })
    }
  }

  if (!page) {
    return null
  }

  return !state.sentMessage ? (
    <Container>
      <PromoContent>
        <Typography fontSize={32} textAlign="center">
          {pageName === 'eld'
            ? 'Unlock ELD/HOS Support Today!'
            : 'Upgrade To Pro Today!'}
        </Typography>
        <TileRow>
          {page.tiles.map(tile => (
            <Tile key={tile.title}>
              <img src={tile.image} alt={tile.title} />
              <Typography fontSize={20}>{tile.title}</Typography>
              <Typography fontSize={14} fontWeight="normal">
                {tile.description}
              </Typography>
            </Tile>
          ))}
        </TileRow>
        <PromoButtonRow>
          {pageName !== 'eld' && (
            <StyledButton onClick={() => {}} background={ascendColors.primary}>
              Watch Demo
            </StyledButton>
          )}

          <StyledButton
            onClick={() =>
              dispatch({type: 'setState', data: {contactSales: true}})
            }
            background={
              state.sentMessage ? ascendColors.platinum : ascendColors.secondary
            }
            disabled={state.sentMessage}
          >
            Contact Sales
          </StyledButton>
        </PromoButtonRow>
      </PromoContent>
      <AlertModal
        showDialog={state.showErrorDialog}
        onSave={() => {
          dispatch({type: 'setState', data: {showErrorDialog: false}})
        }}
        type={'required'}
        title={`We need a little more detail...`}
        text={'Complete all the required fields in the form and try again.'}
        showDenyButton={false}
      />
      <Modal
        showDialog={state.contactSales}
        title={'Contact Sales'}
        onDismiss={() => {
          dispatch({type: 'setState', data: {contactSales: false}})
        }}
        onSave={onSend}
        renderFooter={true}
        submitButtonText="Send"
      >
        <ModalBody>
          <InputWithLabel
            name={'firstName'}
            label={'First Name'}
            value={state.firstName}
            onChange={e => {
              dispatch({type: 'setState', data: {firstName: e.target.value}})
            }}
            required={true}
          />
          <InputWithLabel
            name={'lastName'}
            label={'Last Name'}
            value={state.lastName}
            onChange={e => {
              dispatch({type: 'setState', data: {lastName: e.target.value}})
            }}
            required={true}
          />
          <InputWithLabel
            name={'email'}
            label={'Email'}
            value={state.email}
            onChange={e => {
              dispatch({type: 'setState', data: {email: e.target.value}})
            }}
            required={true}
          />
          <TextAreaWithLabel
            name={'note'}
            label={'Note (optional)'}
            defaultValue={state.note}
            onChange={e => {
              dispatch({type: 'setState', data: {note: e.target.value}})
            }}
          />
        </ModalBody>
      </Modal>
    </Container>
  ) : (
    <Container>
      <SentContent>
        <img src={RequestSent} alt={'requestSent'} />
        <div>
          <Typography fontSize={20} marginBottom="10px">
            We got your Request
          </Typography>
          <Typography fontSize={14} fontWeight="normal">
            Sales rep will contact you shortly
          </Typography>
        </div>

        <SendButtonRow>
          <StyledButton
            onClick={() =>
              dispatch({
                type: 'setState',
                data: {contactSales: false, sentMessage: false},
              })
            }
            background={ascendColors.secondary}
          >
            Back
          </StyledButton>
        </SendButtonRow>
      </SentContent>
    </Container>
  )
}

export default PromotionalPage
