import * as React from 'react'
import styled from 'styled-components/macro'
import {QueryClient, useQueryClient} from '@tanstack/react-query'
import {CellContext, ColumnDef} from '@tanstack/react-table'
import {useLocation, Link, useNavigate} from 'react-router'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'
import useAssetList from './hooks/useAssetList'
import {ListContainer, BreadCrumbs, Crumb} from 'common/components'
import {deleteDialog} from 'common/deleteDialog'
import TableActionButtons from 'common/tables/TableActionButtons'
import BulkAssetImport from './BullkAssetImport'
import {useStore} from 'common/useStore'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'
import AlertModal from 'common/components/AlertModal'
import {
  useCheckUserRestriction,
  checkAddDeleteEditAssetLowerLevels,
} from 'common/helpers'
import {AssetListType} from 'common/types/typesModule'

export const AssetImage = styled.img`
  max-height: 50px;
  max-width: 70px;
  margin: 5px;
`
export const AssetImageDiv = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

const deleteAsset = (asset: AssetListType, cache: QueryClient) => {
  asset.idAsset &&
    deleteDialog({
      deleteURL: `/assets/${asset.idAsset}`,
      id: asset.idAsset,
      idField: 'idAsset',
      cache,
      reactQueryKey: 'assetsList',
    })
}

const ActionCell = (
  instance: CellContext<AssetListType, unknown>,
  cache: QueryClient,
) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const table = instance.table.getState()
  const canAddDeleteEditAssetLowerLevels = checkAddDeleteEditAssetLowerLevels()
  if (instance.row.original.idAsset) {
    const asset = instance.row.original
    return (
      <React.Fragment>
        <TableActionButtons
          editText={canAddDeleteEditAssetLowerLevels ? 'Edit' : 'View'}
          editPath={`/assets/${asset.idAsset}`}
          onDelete={
            canAddDeleteEditAssetLowerLevels
              ? () => setShowDeleteDialog(true)
              : undefined
          }
          dashboardPath={`/asset-dashboard/${asset.idAsset}`}
          filters={{
            filters: table.columnFilters,
            globalFilter: table.globalFilter,
          }}
          testIds={{
            edit: `edit-asset-${asset.idAsset}`,
            delete: `delete-asset-${asset.idAsset}`,
          }}
        />
        <AlertModal
          showDialog={showDeleteDialog}
          onSave={() => {
            setShowDeleteDialog(false)
            deleteAsset(asset, cache)
          }}
          onDismiss={() => setShowDeleteDialog(false)}
          showDenyButton={true}
          title={`Delete the asset ${asset.Asset_Label}?`}
          text={'The asset and its details will be permanently removed.'}
          type={'delete'}
        />
      </React.Fragment>
    )
  }
  return null
}

const AssetLinkCell = (instance: CellContext<AssetListType, unknown>) => {
  if (instance.row.original.idAsset) {
    const asset = instance.row.original
    const table = instance.table.getState()
    return (
      <StyledLink
        to={`/assets/${asset.idAsset}`}
        state={{
          filters: {
            filters: table.columnFilters,
            globalFilter: table.globalFilter,
          },
        }}
      >{`${asset.Asset_Label}`}</StyledLink>
    )
  }
  return null
}

const AssetImageCell = (instance: CellContext<AssetListType, unknown>) => {
  const value = instance.getValue()
  if (value) {
    return (
      <AssetImageDiv>
        <AssetImage src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${value}`} />
      </AssetImageDiv>
    )
  }
  return null
}

const AssetList = () => {
  const {data, status, isFetching} = useAssetList()
  const cache = useQueryClient()
  const {state: locationState} = useLocation()
  const navigate = useNavigate()
  const setBreadcrumbs = useStore(state => state.setBreadcrumbs)
  const showButton = useCheckUserRestriction('assets')
  const canAddDeleteEditAssetLowerLevels = checkAddDeleteEditAssetLowerLevels()
  const columns: ColumnDef<AssetListType>[] = [
    {
      header: 'Name',
      id: 'name',
      accessorKey: 'Asset_Label',
      cell: AssetLinkCell,
    },
    {
      header: 'Image',
      id: 'image',
      accessorKey: 'Asset_Icon_Full_Path',
      cell: AssetImageCell,
      size: 100,
    },
    {
      header: 'Description',
      id: 'description',
      accessorKey: 'Asset_Description',
    },
    {
      header: 'Driver',
      id: 'currentDriver',
      accessorKey: 'CurrentDriver',
    },
    {
      header: 'Year',
      id: 'year',
      accessorKey: 'Year',
      size: 80,
    },
    {
      header: 'Make',
      id: 'Make',
      accessorKey: 'Make',
    },
    {
      header: 'Model',
      id: 'Model',
      accessorKey: 'Model',
    },
    {
      header: 'Equipment ID',
      id: 'equipmentid',
      accessorKey: 'EquipmentID',
    },
    {
      header: 'GPS Serial #',
      id: 'gpsserial',
      accessorKey: 'Serial_Number_Displayed',
    },
    {
      header: 'Equipment Type',
      id: 'equipmentType',
      accessorKey: 'EquipmentType',
    },
    {
      header: 'Actions',
      id: 'Action',
      cell: (instance: CellContext<AssetListType, unknown>) =>
        ActionCell(instance, cache),
      size: 120,
      enableResizing: false,
      enableSorting: false,
      meta: {
        disableExport: true,
      },
    },
    {
      header: '',
      accessorKey: 'idAsset',
      cell: '',
      maxSize: 0,
      enableResizing: false,
    },
  ]

  const initialFilters = locationState?.filters
    ? locationState.filters
    : {filters: [], globalFilter: ''}

  React.useEffect(() => {
    //clear location state after loading so it doesn't persist
    window.history.replaceState({}, document.title)
  }, [])

  React.useEffect(() => {
    setBreadcrumbs(
      <BreadCrumbs>
        <Crumb href="/map">Home</Crumb>
        <Crumb>Assets</Crumb>
      </BreadCrumbs>,
    )
  }, [setBreadcrumbs])
  return (
    <ListContainer>
      {status === 'error' ? (
        <div>There was an error loading the assets.</div>
      ) : (
        <>
          <ResizableTable
            data={data || []}
            columns={columns}
            title={'Assets'}
            initSort={[{id: 'name', desc: false}]}
            status={status}
            initialFilters={initialFilters}
            addOptions={
              showButton && canAddDeleteEditAssetLowerLevels
                ? {
                    addAction: () => navigate('/asset'),
                    addButtonText: 'ADD ASSET',
                  }
                : undefined
            }
            isUpdating={isFetching}
          >
            {canAddDeleteEditAssetLowerLevels && <BulkAssetImport />}
          </ResizableTable>
        </>
      )}
    </ListContainer>
  )
}
export default AssetList
