import {ascendColors} from 'common/GlobalStyles'
import {useMapStore} from 'map/useMapStore'
import * as React from 'react'
import styled from 'styled-components/macro'
import {IconProps} from 'phosphor-react'

const Container = styled.div<{
  label: string
  selected: boolean
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  background-color: ${({selected}) => (selected ? 'var(--primary)' : 'white')};
  border: ${({label}) =>
    label === 'ALERTS'
      ? '1px solid var(--asc-vermilion)'
      : '1px solid var(--asc-sonicsilver)'};
  border-radius: 20px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-right: 5px;
  color: ${({selected, fontOnPrimary}) =>
    selected ? fontOnPrimary : 'var(--primary)'};
  svg {
    color: ${({label, selected}) =>
      label === 'ALERTS'
        ? 'var(--asc-vermilion)'
        : selected
        ? 'var(--secondary)'
        : 'var(--asc-sonicsilver)'};
  }
  :hover {
    span {
      color: ${p => p.fontOnSecondary};
    }
    border: ${({label}) =>
      label === 'ALERTS'
        ? '1px solid var(--asc-vermilion)'
        : '1px solid var(--secondary)'};
    svg {
      color: ${({label}) =>
        label === 'ALERTS' ? 'var(--asc-vermilion)' : 'var(--primary)'};
    }
    cursor: pointer;
    background-color: ${({label}) =>
      label === 'ALERTS' ? 'var(--asc-lavender)' : 'var(--secondary)'};
    div {
      background-color: ${({label}) =>
        label === 'ALERTS' ? 'var(--asc-vermilion)' : 'var(--primary)'};
      color: ${({label, fontOnPrimary}) =>
        label === 'ALERTS' ? 'white' : fontOnPrimary};
    }
  }
  span {
    text-transform: uppercase;
    font-weight: 700;
  }
`
const Count = styled.div<{
  label: string
  selected: boolean
  fontOnSecondary: string
}>`
  padding: 3px 5px;
  border-radius: 10px;
  background-color: ${({label, selected}) =>
    label === 'ALERTS'
      ? 'var(--asc-vermilion)'
      : selected
      ? 'var(--secondary)'
      : 'var(--asc-sonicsilver)'};
  color: ${({label, selected, fontOnSecondary}) =>
    label === 'ALERTS' ? 'white' : selected ? fontOnSecondary : 'white'};
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
`

type Props = {
  label: string
  item: {count: number; assets: number[]; color?: string}
  setAssetFilter: (filter: {
    label: string | null
    filteredAssetIDs: number[]
  }) => void
  assetFilter: {
    label: string
    filteredAssetIDs: number[]
  }
  toggleShowingAlerts: () => void
  showingAlerts?: boolean
  Icon?: React.FC<IconProps>
  className?: string
}

const GutButton = ({
  label,
  item,
  setAssetFilter,
  assetFilter,
  toggleShowingAlerts,
  Icon,
  className,
}: Props) => {
  const onClick = () => {
    useMapStore.setState({showAssetMarkers: true})
    if (label === assetFilter?.label) {
      setAssetFilter({label: null, filteredAssetIDs: []})
    } else {
      if (label !== 'ALERTS') {
        setAssetFilter({label, filteredAssetIDs: item.assets})
      } else {
        toggleShowingAlerts()
      }
    }
  }
  const labelString = `${label}`
  return (
    <Container
      onClick={onClick}
      label={label}
      selected={assetFilter?.label === label}
      className={className}
      data-testid={`${labelString.toLowerCase()}-chip`}
      fontOnPrimary={ascendColors.fontOnPrimary}
      fontOnSecondary={ascendColors.fontOnSecondary}
    >
      {Icon ? <Icon weight="fill" size={22} /> : <span>{label}</span>}
      <Count
        label={label}
        selected={assetFilter?.label === label}
        fontOnSecondary={ascendColors.fontOnSecondary}
      >
        {item.count}
      </Count>
    </Container>
  )
}
export default GutButton
