import {ColumnDef} from '@tanstack/react-table'
import RenderCell from './RenderCell'
// import {useStore} from 'common/useStore'
import AssetImageCell from './AssetImageCell'
import {SchemaType} from 'account/customizeViews/SubmitPanel'
import {Asset} from 'common/types/opening1Response'
import DirectDistanceCell from './DirectDistanceCell'

// export const setHeight = ({event, dragbarHeight}) => {
//   // if (isDragging) {
//   let page = document.getElementById('page')
//   const topSection = document.getElementById('topsection')
//   const bottomSection = document.getElementById('bottomsection')
//   const topRect = topSection.getBoundingClientRect()
//   const bottomRect = bottomSection.getBoundingClientRect()
//   const totalHeight = topRect.height + dragbarHeight + bottomRect.height
//   const topHeight = event.clientY - topRect.top
//   const bottomHeight = totalHeight - topHeight - dragbarHeight

//   let rows = [topHeight, dragbarHeight, bottomHeight]

//   let newColDefn = rows.map(c => c.toString() + 'px').join(' ')

//   page.style.gridTemplateRows = newColDefn

//   event.preventDefault()
//   return topHeight
//   // }
// }

export const thresholdclass = function (
  field: SchemaType,
  value: number,
  asset: Asset,
) {
  let tclass = ''
  let threshholdFactor = 1
  switch (field.threshold.ThresholdType) {
    case 0:
      //Check for battery voltage to calculate factor
      if (field.deviceFieldName === 'Last_Battery_Voltage' && value) {
        if (asset?.BatteryTypeVoltage) {
          threshholdFactor = parseInt(asset.BatteryTypeVoltage) / 12
        }
      }
      if (
        value >=
          Number(field.threshold.ValueGreenLowerLimit) * threshholdFactor &&
        value <= Number(field.threshold.ValueGreenUpperLimit) * threshholdFactor
      ) {
        tclass = ''
      } else if (
        value >=
          Number(field.threshold.ValueYellowLowerLimit) * threshholdFactor &&
        value <=
          Number(field.threshold.ValueYellowUpperLimit) * threshholdFactor
      ) {
        tclass = 'yellow'
      } else if (value && typeof value === 'number') {
        // make sure there is a value before marking it red
        tclass = 'red'
      }
      break
    case 1:
      if (Number(value) === Number(field.threshold.ValueGreenLowerLimit)) {
        tclass = ''
      } else if (value && typeof value === 'number') {
        // make sure there is a value before marking it red
        tclass = 'red'
      }
      break
    default:
      return false
  }
  return tclass
}

export const buildColumns = ({
  schema,
  showingNearestAssetDistances,
}: {
  schema: SchemaType[]
  showingNearestAssetDistances: boolean
}) => {
  if (!schema) return []
  const newColumns: Partial<ColumnDef<Asset, number>>[] = []
  //change lowercase m to upper case M
  // const timeFormat = userConfig?.Time_Format === '12HR' ? 'h:mm a' : 'HH:mm a'
  // const userDateTimeFormat = userConfig
  //   ? userConfig?.Date_Format.replace('mm', 'MM') + ' ' + timeFormat
  //   : 'MM/dd/yyyy h:mm a'
  schema.map(s => {
    if (s.deviceFieldName === 'FindNearestAssetDistance') {
      s.fieldVisibility = 1
    }
    return s
  })
  const filteredFieldVisibiltySchema = schema?.filter(
    item => item.fieldVisibility,
  )
  if (filteredFieldVisibiltySchema) {
    filteredFieldVisibiltySchema.forEach(function (field) {
      if (field.FieldMapping === 'FindNearestAssetDistance') {
        const columnObj: Partial<ColumnDef<Asset, number>> = {}
        columnObj.enableColumnFilter = false
        //@ts-expect-error I don't know why!!
        columnObj.accessorKey = 'FindNearestAssetDistance'
        columnObj.id = 'FindNearestAssetDistance'
        if (showingNearestAssetDistances) {
          columnObj.header = 'Direct Distance'
          columnObj.cell = DirectDistanceCell
          columnObj.size = 125
        } else {
          columnObj.header = ''
          columnObj.size = 0
          columnObj.cell = ''
          columnObj.maxSize = 0
          columnObj.enableResizing = false
          columnObj.meta = {
            disableExport: true,
          }
        }
        newColumns.push(columnObj)
      } else {
        const columnObj: Partial<ColumnDef<Asset, number>> = {}
        // console.log('here')
        columnObj.header = field.columnheader
        //@ts-expect-error I don't know why!!
        columnObj.accessorKey =
          String(field.FieldMapping?.replace('>', '.')) || ''
        columnObj.size = field.width
        // columnObj.formatting = field.type
        // columnObj.disableFilters = field.columnfilterable
        // columnObj.decimals = field.precision
        // columnObj.threshold = field.threshold
        if (field.FieldMapping === 'Asset_Icon_Full_Path') {
          columnObj.cell = AssetImageCell
          columnObj.enableColumnFilter = false
          // } else if (field.FieldMapping === 'FindNearestAssetDistance') {
          // columnObj.disableExport = true
        } else {
          columnObj.id = field.FieldMapping
          if (field.type === 'number' || field.type === 'date') {
            columnObj.cell = instance => RenderCell(instance, field)
            columnObj.enableColumnFilter = false
            if (field.FieldMapping === 'Device>Last_Location_DateTime_Local') {
              //@ts-expect-error I don't know why!!
              columnObj.accessorFn = (instance: Asset) =>
                instance.Device?.Last_Location_DateTime_Local
                  ? new Date(instance.Device.Last_Location_DateTime_Local)
                  : null
            }
          }
        }
        newColumns.push(columnObj)
      }
    })
  }

  return newColumns as ColumnDef<Asset, number>[]
}
