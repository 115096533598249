import * as React from 'react'
import styled from 'styled-components/macro'
import {InputWithLabel, Input} from 'common/components'
import {StyledLabel} from '../../common/components/StyledComponents'
import {Info, Circle} from 'phosphor-react'
import SearchLocationInput from 'common/components/SearchLocationInput'
import {
  reducer,
  initialState as tripShareInitialState,
} from 'tripshare/TripShareModal/tripShareModalHelpers'
import {isEmail, phoneNumbersCheck} from 'common/helpers'
import {isNumberOrComma, ordinalNumber} from 'common/helpersNumber'

import {
  ModalTitle,
  Container,
  StyledMessageInput,
  LocationContainer,
  InfoContainer,
  InfoText,
  FormContainer,
  DestinationContainer,
  ContactContainer,
  PhoneDescriptionList,
  TextArea,
  AssetLabel,
  EmailDescription,
  PhoneDescription,
} from 'tripshare/TripShareModal/styledComponents/StyledComponents'
import {SubmitValues} from 'tripshare/TripShareModal/tripShareTypes'
import {Asset} from 'common/types/opening1Response'
import Modal from 'common/Modal'

const MAX_EXPIRES_TIME = 160
const StyledModal = styled(Modal)`
  h2 {
    width: 100%;
  }
`

const Error = styled.span`
  color: #d9534f;
  font-style: italic;
  font-size: 85%;
`

type Props = {
  setShowTripShareModal: (showModal: boolean) => void
  setShowConfirmModal: (showModal: boolean) => void
  showingTripShareModal: boolean
  asset: Asset
  onPostTrip: (data: SubmitValues) => void
}
const TripShareModal = ({
  setShowTripShareModal,
  setShowConfirmModal,
  showingTripShareModal = false,
  asset,
  onPostTrip,
}: Props) => {
  const [tripShareState, tripShareDispatch] = React.useReducer(
    reducer,
    tripShareInitialState,
  )
  const [marker, setMarker] = React.useState<{center: google.maps.LatLng}>()
  const [formattedAddress, setFormattedAddress] = React.useState<string>()

  function handleCancel() {
    tripShareDispatch({
      type: 'setState',
      data: tripShareInitialState,
    })
    setShowTripShareModal(false)
  }

  function getDestinationLocation() {
    if (marker?.center) {
      const lat = marker.center.lat()
      const lng = marker.center.lng()
      return `POINT (${lng} ${lat})`
    }
    return undefined
  }

  const handleEmailUpdate = (emailString: string) => {
    let validEmail = true
    let ordinalNumberEmail = ''
    if (emailString.length > 0) {
      const emails = emailString.split(',')
      emails.forEach((email: string, index: number) => {
        if (validEmail && !isEmail(email)) {
          validEmail = false
          ordinalNumberEmail = ordinalNumber(index)
        }
      })
    } else {
      validEmail = false
    }
    tripShareDispatch({
      type: 'setState',
      data: {
        validEmail,
        ordinalNumberEmail,
        notification: {
          ...tripShareState.notification,
          Email_List: emailString,
        },
      },
    })
  }

  const handleSmsUpdate = (smsString: string) => {
    let validPhone = true
    let ordinalNumberPhone = ''
    if (smsString.length > 0) {
      const isValid = phoneNumbersCheck(smsString)
      validPhone = isValid.validNumbers
      ordinalNumberPhone = isValid.problemNumber
    } else {
      validPhone = false
    }
    tripShareDispatch({
      type: 'setState',
      data: {
        ...tripShareState.notification,
        validPhone,
        ordinalNumberPhone,
        notification: {
          ...tripShareState.notification,
          SMS_List: smsString,
        },
      },
    })
  }

  const handleSubmit = () => {
    const submitValues = {
      destinationAddress: formattedAddress || undefined,
      destinationLocation: getDestinationLocation(),
      message: tripShareState.message || undefined,
      hoursToLive: tripShareState.expiresIn,
      emailAddresses: tripShareState.notification.Email_List || undefined,
      smsNumbers: tripShareState.notification.SMS_List || undefined,
      assets: asset?.idAsset.toString(),
    }

    onPostTrip({...submitValues})

    tripShareDispatch({
      type: 'setState',
      data: tripShareInitialState,
    })

    setShowConfirmModal(true)
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    if (
      MAX_EXPIRES_TIME &&
      Number(event.target.value) > MAX_EXPIRES_TIME &&
      event.target.name === 'expiresIn'
    ) {
      const inputError = {
        message: `Expire In value must be less than ${MAX_EXPIRES_TIME} characters.`,
      }
      tripShareDispatch({
        type: 'setState',
        data: {errors: {[event.target.name]: inputError}},
      })
    } else {
      tripShareDispatch({
        type: 'setState',
        data: {
          [event.currentTarget.name]: event.currentTarget.value,
          errors: {[event.target.name]: {message: ''}},
        },
      })
    }
  }

  const renderPhoneDescription = (text: string) => {
    // Was unable to make default bullets smaller to match design.
    // Used Circle Icon so we can manage bullet sizes.
    return (
      <li>
        <PhoneDescription>
          <Circle size={3} weight="fill" />
          <div>{text}</div>
        </PhoneDescription>
      </li>
    )
  }

  return (
    <StyledModal
      title={
        <ModalTitle>
          <div>Share this Trip</div>
          <AssetLabel>{asset?.Asset_Label}</AssetLabel>
        </ModalTitle>
      }
      onDismiss={handleCancel}
      onSave={handleSubmit}
      showDialog={showingTripShareModal}
      showClose={true}
      width="892"
      renderFooter={true}
      submitButtonText={
        tripShareState?.email || tripShareState?.phone
          ? ' Send Link'
          : 'Create Link'
      }
    >
      <Container>
        <FormContainer>
          <DestinationContainer data-cy="destination-input-container">
            <LocationContainer data-cy="address-input-container">
              <StyledLabel>Destination Address</StyledLabel>
              <SearchLocationInput
                setFormattedAddress={setFormattedAddress}
                updateAddress={data => {
                  setMarker(data?.marker)
                  setFormattedAddress(data?.formattedAddress)
                }}
                formattedAddress={formattedAddress || ''}
                width={100}
                flexdirection={'column'}
                alignitems={'left'}
                errors={{}}
                showTitle={false}
              />
            </LocationContainer>

            <StyledMessageInput data-cy="message-input-container">
              <StyledLabel>Message</StyledLabel>
              <TextArea
                name="message"
                cols={45}
                rows={4}
                onChange={handleInputChange}
                value={tripShareState.message}
                data-cy="trip-message"
              />
            </StyledMessageInput>

            <InputWithLabel
              value={tripShareState.expiresIn}
              type="number"
              onChange={handleInputChange}
              label="Expires In"
              name="expiresIn"
              suffix="hours"
              data-cy="expires-in"
              errors={tripShareState.errors}
            />
          </DestinationContainer>

          <ContactContainer data-cy="contact-input-container">
            <StyledLabel>Email(s)</StyledLabel>
            <Input
              placeholder="email1@domain.com,email2@domain.com"
              value={tripShareState.notification.Email_List}
              onChange={e =>
                handleEmailUpdate(e.currentTarget.value.replace(/\s/g, ''))
              }
              name="email"
            />
            {!tripShareState.validEmail &&
              tripShareState.ordinalNumberEmail && (
                <Error>
                  Your {tripShareState.ordinalNumberEmail} email is not in a
                  valid email format.
                </Error>
              )}
            <EmailDescription>
              Separate multiple email addresses with a comma
            </EmailDescription>

            <StyledLabel>Phone Number(s)</StyledLabel>
            <Input
              placeholder="8001112222,8003334444"
              value={tripShareState.notification.SMS_List}
              onChange={e => {
                if (isNumberOrComma(e.currentTarget.value)) {
                  handleSmsUpdate(e.currentTarget.value)
                }
              }}
              onPaste={event => {
                //replace all dashes before send as a text
                const pasteText = event.clipboardData
                  .getData('Text')
                  .replace(/ /g, '') //remove white spaces
                  .replace(/-/g, '') //remove all dashes
                if (isNumberOrComma(pasteText)) {
                  handleSmsUpdate(pasteText)
                }
              }}
              name="phone"
            />
            {!tripShareState.validPhone &&
              tripShareState.ordinalNumberPhone && (
                <Error>
                  Please check your {tripShareState.ordinalNumberPhone} phone
                  number.
                </Error>
              )}

            <PhoneDescriptionList>
              {renderPhoneDescription(
                'Enter 10-digit phone number only (ex. 5551234567)',
              )}
              {renderPhoneDescription('Separate multiple numbers with a comma')}
              {renderPhoneDescription('No spaces')}
              {renderPhoneDescription(
                'Non-US numbers: enter country code & phone number',
              )}
            </PhoneDescriptionList>
          </ContactContainer>
        </FormContainer>
        <InfoContainer>
          <Info weight="fill" color="var(--asc-moss)" size={20} />
          <InfoText>
            This will create a public link with the image of the asset, driver
            name, vehicle name and the information share above.
          </InfoText>
        </InfoContainer>
      </Container>
    </StyledModal>
  )
}

export default TripShareModal
