import styled from 'styled-components/macro'

export const StyledLabel = styled.label<{noMargin?: string}>`
  font-family: 'Open Sans';
  margin-bottom: ${p => (p.noMargin ? '0px' : '5px')};
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  display: flex;
`
export const Page = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: relative;
`
export const VideoCameraLink = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* min-height: 95px; */
  cursor: pointer;
  svg {
    cursor: pointer;
    :hover {
      fill: var(--secondary) !important;
    }
  }
`
//do not add to this code, please extend this component to add custom styling
export const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2;
`

//do not add to this code, please extend this component to add custom styling
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
//do not add to this code, please extend this component to add custom styling
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageTitleWrapper = styled(Column)`
  padding: 20px;
`

export const PageTitle = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  white-space: nowrap;
`

export const PageSubTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`
export const InfoPill = styled.div<{
  infoPillBackgroundColor: string
  svgMinWidth?: string
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 21px;

  background: ${props => props.infoPillBackgroundColor || ''};
  padding-right: 10px;
  height: 22px;
  min-width: ${props => props.svgMinWidth || '0px'};
  width: auto;

  svg {
    height: 12px !important;
    min-width: 12px !important;
  }
`

export const InfoText = styled.div`
  color: var(--asc-black);
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const InfoPillContainer = styled.div`
  display: flex;
  width: fit-content;
`

export const IconContainer = styled.div`
  display: flex;
  margin-left: 5px;
`
type TypographyProps = {
  fontSize: number
  marginTop?: string
  marginRight?: string
  marginLeft?: string
  marginBottom?: string
  fontWeight?: string
  color?: string
  width?: string
  textAlign?: string
  fontStyle?: string
  whiteSpace?: string
  minWidth?: string
}

export const Typography = styled.div<TypographyProps>`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '700')};
  font-size: ${props => props.fontSize}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '')};
  color: ${props => (props.color ? props.color : '')};
  width: ${props => (props.width ? props.width : '')};
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  font-style: ${props => (props.fontStyle ? props.fontStyle : '')};
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : '')};
  min-width: ${props => (props.minWidth ? props.minWidth : '')};
`
