import React from 'react'
import styled from 'styled-components/macro'
import {ButtonAction, ButtonInput} from './RadioButton'
import {QUERIES} from 'common/constants'
import {ascendColors} from 'common/GlobalStyles'

const ButtonsContainer = styled.div`
  border-radius: 5px;
  margin-right: 15px;
  @media ${QUERIES.tabletAndUp} {
    width: fit-content;
  }
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  button {
    border: 1px solid var(--asc-coolgray);
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    width: 48%;
    @media ${QUERIES.tabletAndUp} {
      width: initial;
    }
  }
`

type Button = {
  active?: boolean
  value: string | number
  disabled?: boolean
  label: string
}

type MultiSelectObj = {
  [key: number]: Button
}

type Props = {
  buttons: Button[]
  setActive: (value: number | string) => void
  active?: number | string
  multipleSelect?: (buttons: MultiSelectObj) => void
}

const ButtonGroup = ({buttons, setActive, active, multipleSelect}: Props) => {
  const handleMultipleSelect = (index: number) => {
    const currentButtons: MultiSelectObj = Object.assign([], buttons)
    currentButtons[index].active = !currentButtons[index].active
    if (multipleSelect) {
      multipleSelect(currentButtons)
    }
  }
  return (
    <ButtonsContainer data-cy="button-group">
      {buttons?.map((button, index) => {
        return (
          <ButtonAction
            key={index}
            type="button"
            active={
              multipleSelect ? button.active || false : active === button.value
            }
            disabled={button.disabled}
            fontOnPrimary={ascendColors.fontOnPrimary}
            fontOnSecondary={ascendColors.fontOnSecondary}
            onClick={() =>
              multipleSelect
                ? handleMultipleSelect(index)
                : setActive(button.value)
            }
          >
            <ButtonInput
              type="radio"
              checked={multipleSelect ? button.active : active === button.value}
              fontOnPrimary={ascendColors.fontOnPrimary}
              fontOnSecondary={ascendColors.fontOnSecondary}
              onChange={() =>
                multipleSelect
                  ? handleMultipleSelect(index)
                  : setActive(button.value)
              }
            />
            {button.label}
          </ButtonAction>
        )
      })}
    </ButtonsContainer>
  )
}

export default ButtonGroup
