import {MarkerWithLabel} from '@googlemaps/markerwithlabel'
import {matchSorter} from 'match-sorter'
import {createLandmarkGeoJson} from 'common/components/LandmarkDrawingManager/createLandmarkGeoJson'
import Marker from 'images/marker/LocationsMarker.svg'
import ZoneMarker from 'images/map/zoneMarker.png'
import {useMapStore} from 'map/useMapStore'

const processPoints = function (geometry, callback, thisArg) {
  if (geometry instanceof window.google.maps.LatLng) {
    callback.call(thisArg, geometry)
  } else if (geometry instanceof window.google.maps.Data.Point) {
    callback.call(thisArg, geometry.get())
  } else {
    geometry.getArray().forEach(function (g) {
      processPoints(g, callback, thisArg)
    })
  }
}

export const removeLocations = (locationIDs, map) => {
  const {getState, setState} = useMapStore
  const markers = getState().locationMarkers || []

  const newMarkers = markers.filter(marker => {
    if (locationIDs?.includes(marker.id)) {
      marker.setMap(null)
      return false
    } else {
      return true
    }
  })
  setState({locationMarkers: newMarkers})
  locationIDs?.forEach(locationID => {
    const feature = map?.data?.getFeatureById(locationID)
    if (feature) {
      map?.data?.remove(feature)
    }
  })
  useMapStore.setState({clickedLocation: null})
}

export const drawLocations = (locations, map) => {
  useMapStore.setState({clickedLocation: null})
  const {getState, setState} = useMapStore
  const markers = getState().locationMarkers || []
  const locationsGeoJSON = createLandmarkGeoJson(locations)
  var bounds = new window.google.maps.LatLngBounds()
  const newMarkers = []
  locationsGeoJSON.features.forEach(feature => {
    var landmarkLabel = feature.geometry.labelcoords
    var labelCenterX = -(feature.properties.Name.length * 7.5) * 0.5
    const labelClass =
      feature.properties.color === 'green'
        ? 'zone-marker-label'
        : 'location-marker-label'
    var newMarker = new MarkerWithLabel({
      position: landmarkLabel,
      draggable: false,
      map: map,
      labelContent: feature.properties.Name,
      labelAnchor: new window.google.maps.Point(labelCenterX, 5),
      labelClass: labelClass,
      labelStyle: {opacity: 0.9},
      icon: feature.properties.color === 'green' ? ZoneMarker : Marker,
      id: feature.properties.id,
    })
    newMarkers.push(newMarker)

    // add listener to the location for zoom in to the location on click

    // if (newMarker) {
    //   const latlng = new window.google.maps.LatLng(landmarkLabel)
    //   const {infowindow} = infoWindowWithCreateLocation({
    //     latlng,
    //     history,
    //     onNearestAssets: () => {
    //       getClosestAssets({assets, latlng, map})
    //     },
    //   })
    //   newMarker.addListener('click', () => {
    //     console.log('clicked', newMarker, map)
    //     infowindow.open({map, anchor: newMarker})
    //   })
    window.google.maps.event.addListener(newMarker, 'click', function () {
      const clickedBounds = new window.google.maps.LatLngBounds()
      const featureId = feature.properties.id
      const lat = newMarker.getPosition().lat()
      const lng = newMarker.getPosition().lng()
      const clickedLocation = {
        id: featureId,
        coordinates: {lat, lng},
      }
      map.data.forEach(function (clickedFeature) {
        if (featureId === clickedFeature.getProperty('id')) {
          processPoints(
            clickedFeature.getGeometry(),
            clickedBounds.extend,
            clickedBounds,
          )
          map.fitBounds(clickedBounds)
        }
      })
      useMapStore.setState({clickedLocation})
    })
    // }
  })

  setState({
    locationMarkers: [...markers, ...newMarkers],
  })
  //add locationGeoJSON to the map data
  map?.data?.addGeoJson(locationsGeoJSON, {
    idPropertyName: 'id',
  })

  map?.data?.forEach(feature => {
    if (feature.getGeometry().getType() === 'Polygon') {
      processPoints(feature.getGeometry(), bounds.extend, bounds)
    }
  })

  map?.data?.setStyle(feature => {
    const type = feature.getGeometry().getType()
    const color =
      feature.getProperty('color') === 'green' ? '#006200' : '#FEC143'
    if (type === 'Polygon') {
      return {
        strokeColor: '#ffffff',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.4,
      }
    } else {
      return {
        icon: feature.getProperty('icon'),
      }
    }
  })
  if (bounds) map?.fitBounds(bounds)
}

export const filterLocations = ({
  allLocations,
  searchValue,
  typeSelected,
  showing,
}) => {
  let locations = allLocations
  let types = []
  if (searchValue) {
    locations = matchSorter(allLocations, searchValue, {
      keys: [
        'Landmark_Name',
        'Landmark_Style',
        'Landmark_Label',
        'Landmark_Address_Line1',
        'Landmark_Address_Line2',
        'Landmark_City',
        'Landmark_Region',
        'Landmark_Country',
      ],
      threshold: matchSorter.rankings.CONTAINS,
    })
  }

  if (showing === 'Locations') {
    locations = locations.filter(loc => loc.Landmark_Category === 1)
  }

  if (typeSelected !== 'All Types') {
    const typeFilteredLocations = []
    locations.forEach(l => {
      if (l.Landmark_Style === typeSelected) {
        typeFilteredLocations.push(l)
      }
    })
    locations = typeFilteredLocations
  }

  locations.forEach(location => {
    if (location.Landmark_Style && !types.includes(location.Landmark_Style)) {
      types.push(location.Landmark_Style)
    }
  })
  types = types.sort((a, b) => a.localeCompare(b))
  types.unshift('All Types')
  return {locations, types}
}

// export const filterZones = ({allZones, searchValue}) => {
//   let zones = allZones
//   let types = []

//   if (searchValue) {
//     zones = matchSorter(allZones, searchValue, {
//       keys: [
//         'Landmark_Name',
//         'Landmark_Style',
//         'Landmark_Label',
//         'Landmark_Address_Line1',
//         'Landmark_Address_Line2',
//         'Landmark_City',
//         'Landmark_Region',
//         'Landmark_Country',
//       ],
//       threshold: matchSorter.rankings.CONTAINS,
//     })
//   }

//   zones.forEach(zone => {
//     if (zone.Landmark_Style && !types.includes(zone.Landmark_Style)) {
//       types.push(zone.Landmark_Style)
//     }
//   })
//   types = types.sort((a, b) => a.localeCompare(b))
//   types.unshift('All')
//   return {zones: zones, types}
// }
