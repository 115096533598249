export const VERSION = '3.44'
import {object, string, number} from 'zod'
import {ascendColors} from './GlobalStyles'
let appURI = `../`
let appAPIURI = `../api/`
let appWS = `..:11502/`
let azureImageBase = ''
let reportingServer = ''
let dynamicGroupsURI = ''
let appFlavor: 'ATT' | 'Geoforce' = 'Geoforce'
const devAppWS = 'https://dev.temeda.com:11502/'
const prodAppWS = 'https://www.temeda.com:11502/'
const prodATTWS = 'https://att.temeda.com:11502/'

const devImageBase = 'https://temedadevwebimagessa.blob.core.windows.net'
const prodImageBase = 'https://temedasmbwebimagessa.blob.core.windows.net'
const prodATTImageBase = 'https://temedaattwebimagessa.blob.core.windows.net'

const devReportingServer =
  'https://reports.ascend.dev.geoforce.net/reports/rdPage.aspx'
const prodReportingServer =
  'https://reports.ascend.geoforce.com/reports/rdPage.aspx'
const prodATTReportingServer =
  'https://reports.eams.geoforce.com/reports/rdPage.aspx'

const devDynamicGroupsURI =
  'https://dev-temeda-api-management.azure-api.net/gql'
const prodDynamicGroupsURI = `https://temeda.azure-api.net/gql`
const prodATTDynamicGroupsURI = `https://temeda.azure-api.net/gql-att`

const origin = window.location.origin
// if (origin.includes('qa.geoforce.net')) {
//   appURI = 'https://att.temeda.com/'
//   appAPIURI = 'https://att.temeda.com/api/'
if (origin.includes('localhost')) {
  appURI = 'https://dev.temeda.com/'
  appAPIURI = 'https://dev.temeda.com/api/'
} else {
  appURI = origin + '/'
  appAPIURI = origin + '/api/'
}
if (
  origin.includes('localhost') ||
  origin.includes('ascend.dev.geoforce.net') ||
  origin.includes('ascend.qa.geoforce.net')
) {
  //** geoforce dev */
  appWS = devAppWS
  azureImageBase = devImageBase
  reportingServer = devReportingServer
  dynamicGroupsURI = devDynamicGroupsURI
} else {
  if (origin.includes('eams.geoforce.com')) {
    appWS = prodATTWS
    azureImageBase = prodATTImageBase
    reportingServer = prodATTReportingServer
    dynamicGroupsURI = prodATTDynamicGroupsURI
    appFlavor = 'ATT'
  } else {
    appWS = prodAppWS
    azureImageBase = prodImageBase
    reportingServer = prodReportingServer
    dynamicGroupsURI = prodDynamicGroupsURI
  }
}

export const APP_URI = appURI
export const APP_API_URI = appAPIURI
export const APP_WS = appWS
export const APP_REPORTING_SERVER = reportingServer
export const DYNAMIC_GROUPS_API_URI = dynamicGroupsURI
export const APP_FLAVOR: 'ATT' | 'Geoforce' = appFlavor

export const GOOGLE_KEYS = {
  client: 'gme-temedallc',
  libraries: ['places', 'geometry', 'drawing', 'directions'],
}

export const LEFT_MENU_WIDTH_EXPANDED = 258
export const LEFT_MENU_PADDING = 37
export const LEFT_MENU_WIDTH_COLLAPSED = 58
export const HEADING_HEIGHT = 46

export const AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE = azureImageBase
export const REPORTING_SERVER = reportingServer

export const BREAKPOINTS = {
  phoneMax: 600,
  tabletMax: 950,
  laptopMax: 1300,
}
export const QUERIES = {
  phoneAndDown: `(max-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  phoneAndUp: `(min-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
}
export const WINDOWTABS: {[key: string]: Window | null} = {assetDashboard: null}

export const WEATHER_KEYS = {
  id: 'LHUzdIDqmvg6hwMd8yqsN',
  secret: 'JfN6QvW5grJzvOVXRbN5NSGd86TgqT9ev7PNv1SC',
  //keys api for weather app
}
export type MarkerColor = {
  1: string
  2: string
  3: string
  4: string
  5: string
  6: string
  7: string
  8: string
  9: string
  10: string
  11: string
  12: string
  unknown: string
}
export const MARKER_COLORS: MarkerColor = {
  1: ascendColors.vermilion, //--asc-vermilion
  2: ascendColors.darkolive, //--primary
  3: ascendColors.gold, //--asc-gold
  4: ascendColors.tan, //--asc-tan
  5: ascendColors.moss, //--asc-moss
  6: ascendColors.lime, //--secondary
  7: ascendColors.geoforceLightBlue, //--asc-denim
  8: ascendColors.blue, //--asc-blue
  9: ascendColors.purple, //--asc-purple
  10: ascendColors.flamingo, //--asc-flamingo
  11: ascendColors.brown, //--asc-brown
  12: '#000000',
  unknown: '#000000',
}

export const ANIMATION_DURATION_MILLISECONDS = 10000
export const UPDATE_INTERVAL_MILLISECONDS = 20000

export const emailSchema = object({
  email: string().email(),
})

export const LatitudeSchema = object({
  latitude: number().min(-90).max(90),
})
export const LongitudeSchema = object({
  longitude: number().min(-180).max(180),
})

// export function validateEmail(email: string) {
//   const re =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   return re.test(email)
// }
