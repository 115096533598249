import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export type CustomerType = {
  idcustomer?: number
  idCustomer?: number
  customer_name?: string
  Customer_Name?: string | null
  CRM_Account_Nbr?: string
  Dealer_Notes?: string
  CustomerType_Label?: string
  Dealer_Name?: string
  FullCustomerAddress?: string
  Latitude?: number
  Longitude?: number
  devicecount?: number
  assetcount?: number
  idDealer?: number
  Created_On: string
  DeviceList?: string
  Dealer_Type?: string
  CRM_ID?: string
  AccountCustomerCount?: number
  Dashboard?: boolean
  Maintenance?: boolean
  Tranztec?: boolean
  timezone?: number
  Address_Line1?: string
  Locality?: string
  Region?: string
  Postal_Code?: string
  isoCountryCode?: string
  NetSuiteInternalId?: string
  subscriptionLevel?: 'base' | 'pro'
}

const getCustomers = async () => {
  const {data}: {data: CustomerType[]} = await Axios.get(`/customers`)

  return data.map((customer: CustomerType) => ({
    ...customer,
    Customer_Name: customer.customer_name || customer.Customer_Name || '',
  }))
}

const useCustomers = () => {
  const user = useStore(state => state.user)
  return useQuery(['customers'], () => getCustomers(), {
    enabled: Boolean(user.token),
  })
}

export default useCustomers
