import * as React from 'react'
import styled from 'styled-components/macro'
import ButtonDropDown from '../DropDownButton/ButtonDropDown'
import {Plus} from 'phosphor-react'

type OptionType = {
  key: number
  text: string
  value: number
  preview: string
}

const StyledButtonDropdown = styled(ButtonDropDown)`
  margin-right: 10px;
  font-size: 13px;
  #dropdown-card {
    top: 30px; //height on the dropdown label
    width: 100%;
    ul {
      max-height: 233px;
      overflow-y: auto;
      overflow-x: hidden;
      li {
        font-size: 12px;
      }
    }
  }
`

type InsertAtCursorDropdownProps = {
  name: string
  insertOptions: OptionType[]
  value: string
  cursorPosition: number
  handleInputChange: ({name, value}: {name: string; value: string}) => void
}
//use text areas instead of inputs
const InsertAtCursorDropdown: React.FC<InsertAtCursorDropdownProps> = ({
  name,
  insertOptions,
  value,
  cursorPosition,
  handleInputChange,
}) => {
  const onChange = (e: number) => {
    const insertText = `{${insertOptions[e].text}}`
    const beforeText = value.substring(0, cursorPosition || 0)
    const afterText = value.substring(cursorPosition || 0, value.length)
    const newValue = beforeText + insertText + afterText
    handleInputChange({
      name: name,
      value: newValue,
    })
  }
  return (
    <StyledButtonDropdown
      text={
        <>
          <Plus />
          Insert Token at Cursor
        </>
      }
      direction={'left'}
      options={insertOptions}
      onChange={onChange}
    />
  )
}
export default InsertAtCursorDropdown
