import {create} from 'zustand'
import {LEFT_MENU_WIDTH_EXPANDED} from 'common/constants'
import {ReactNode} from 'react'
// import {devtools} from 'zustand/middleware'

interface Branding {
  idBranding: number
  Logo: string
  Logo_Click_URL: string
  Privacy_Policy: string
  Dealers: string[]
  Customers: string[]
  InsertNewBrandingImageFlag: boolean
}

interface CustomerConfiguration {
  idCustomerConfiguration: number
  idCustomer: number
  ConfigContext: string
  ConfigGroup: string
  ConfigSettings: string
  Description: string
}

interface ParentCustomer {
  idCustomer: number
  Customer_Name: string
  idCustomerType: number
  HierarchyLevel: number
  AssetCount: number
  idParentCustomer?: number
}

interface AvailableCustomer {
  idCustomer: number
  Customer_Name: string
  IsTopmostCustomer: boolean | null
  IsHierarchyRoot: boolean | null
  HierarchyLevel: number
  idCustomerType: number
  ParentCustomers: ParentCustomer[]
  HierarchyPath: string
}

interface UserRole {
  idUserRole: number
  Role_Label: string
  SortOrder: number
}

export interface UserConfig {
  // activeDeviceCount: number;
  Fuel_Rate_UOM: string
  Distance_UOM: 'mi' | 'km'
  Volume_UOM: string
  Temperature_UOM: string
  Pressure_UOM: string
  TZ_String: string
  TZ_Moment_Name: string
  Branding: Branding
  CustomerDCDGroupId: number
  WebServer: string
  CustomerType: string
  idCustomerType: number
  CustomerConfigurations: CustomerConfiguration[]
  CustomerConfigDisplayDealerships: boolean
  CustomerConfigDisplayCustomers: boolean
  idDCDCustomerGroup: number
  Customer_Name: string
  Distance_Format: number
  Speed_Format: number
  FuelEfficiency_Format: number
  Temperature_Format: number
  Volume_Format: number
  Pressure_Format: number
  IsEnterpriseCustomer: boolean
  AvailableCustomers: AvailableCustomer[]
  idUser: number
  Email_Address: string
  First_Name: string
  Last_Name: string
  idCustomer: number
  Role_Id: number
  TimeZone: number
  DST_Flag: boolean
  Speed_UOM: string
  Date_Format: string
  Time_Format: string
  Default_View: string
  Default_Mapview: number
  Asset_Labels: boolean
  TZ_Index: number
  EmployeeID: string
  UserRole: UserRole
  RestrictedAdminAccess?: string | null
  disabledFeatures?: string[]
  IsTemedaAdminAndIsImpersonated?: boolean
  Is_Support_Admin?: boolean
  SubscriptionType: 'PRO' | 'BASE'
}

export const ORoleType = {
  SuperAdmin: 0, //superuser
  Administrator: 1, //user admin
  User: 2, //user non-admin
  DealerAdministrator: 3, // dealer admin
  DealerSupport: 4, // dealer support
  DealerRep: 5, // dealer rep
  Anonymous: 6,
  Vendor: 7,
  Sales: 8, //user non-admin
  Service: 9, //user non-admin
  Technician: 10, //user non-admin
  RestrictedAdministrator: 11,
}

// It requires an extra line to pull out the keys
export type RoleType = (typeof ORoleType)[keyof typeof ORoleType]
export type RoleLabel = keyof typeof ORoleType

export interface User {
  email: string
  token: string
  impersonatingEmail: string
  role: RoleType | null
  Is_Support_Admin?: boolean
}

type State = {
  user: User
  removeUser: () => void
  // setUser: (user: User) => void
  // setUserConfig: (userConfig: UserConfig) => void
  userConfig: UserConfig | null
  emptyStore: () => void
  leftMenuWidth: number
  breadcrumbs: ReactNode
  gridOpen: boolean
  setBreadcrumbs: (breadcrumb: ReactNode) => void
  setGridOpen: (gridOpen: boolean) => void
  isROCustomer: boolean
  assetListOpen: boolean
  setAssetListOpen: (assetListOpen: boolean) => void
  setLeftMenuWidth: (width: number) => void
}

const emptyUser = {
  email: '',
  token: '',
  impersonatingEmail: '',
  role: null,
}

const initialStore = {
  user: emptyUser,
  userConfig: null,
  leftMenuWidth: LEFT_MENU_WIDTH_EXPANDED || 230,
  role: null,
  breadcrumbs: null,
  gridOpen: false,
  assetListOpen: true,
  selectedOrders: [],
  isROCustomer: false,
}

export const useStore = create<State>(
  // devtools(set => ({
  set => ({
    ...initialStore,
    removeUser: () => set({user: emptyUser, userConfig: null}),
    emptyStore: () => set(state => ({...state, ...initialStore})),
    setLeftMenuWidth: (width: number) => set(() => ({leftMenuWidth: width})),
    setBreadcrumbs: (breadcrumb: ReactNode) =>
      set(state => ({...state, breadcrumbs: breadcrumb})),
    setGridOpen: (gridOpen: boolean) => set(state => ({...state, gridOpen})),
    setAssetListOpen: (assetListOpen: boolean) =>
      set(state => ({...state, assetListOpen})),
  }),
  // })),
)
