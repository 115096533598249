import styled from 'styled-components/macro'
import Button from '../Button'
import {ascendColors} from 'common/GlobalStyles'

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px #0000002d;
`
export const PromoContent = styled.div`
  gap: 80px;
  display: flex;
  flex-direction: column;
`
export const SentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 40px;
`
export const TileRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`
export const Tile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 300px;
  gap: 20px;
`
export const PromoButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`
export const SendButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

export const StyledButton = styled(Button)<{
  background: string
  disabled?: boolean
}>`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props =>
    props.disabled
      ? ascendColors.graphite
      : props.background === ascendColors.primary
      ? ascendColors.fontOnPrimary
      : props.background === ascendColors.secondary
      ? ascendColors.fontOnSecondary
      : ascendColors.primary};
  background-color: ${props => props.background};
  padding: 15px 20px;
  :hover {
    color: ${props =>
      props.disabled
        ? ascendColors.graphite
        : props.background === ascendColors.primary
        ? ascendColors.fontOnSecondary
        : ascendColors.fontOnPrimary};
    background-color: ${props =>
      props.disabled
        ? props.background
        : props.background === ascendColors.primary
        ? ascendColors.secondary
        : ascendColors.primary};
  }
`
