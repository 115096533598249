import * as React from 'react'
import styled from 'styled-components/macro'
import {Form, Label, Button, Input, Icon} from 'semantic-ui-react'
import {parse, format, isAfter, endOfDay, addDays, subDays} from 'date-fns'
import InputErrorMessage from './InputErrorMessage'
import DatePicker from 'common/components/DatePicker'
import useComponentVisible from 'common/useComponentVisible'
import {StyledLabel} from 'common/components/StyledComponents'
import {useStore} from 'common/useStore'
import {CalendarBlank} from 'phosphor-react'
import {ErrorType} from 'common/types'
const Container = styled.div`
  position: relative;
  width: ${({width}: {width: string}) => width};
`

const StyledIconLabel = styled(Label)`
  &&& {
    border: 1px solid var(--asc-coolgray) !important;
    &:hover {
      cursor: ${p => (p.disabled ? 'default' : 'pointer')};
    }
    margin: 0;
  }
`

const RequiredAsterisk = styled.span`
  margin-left: 5px;
  ::after {
    content: ' * ';
    font-size: 0.9rem;
    color: var(--asc-vermilion);
  }
`

const CalendarContainer = styled.div<{calendarPosition: string}>`
  background-color: white;
  position: absolute;
  bottom: ${p => (p.calendarPosition === 'top-right' ? '41px' : null)};
  left: ${p => (p.calendarPosition === 'top-right' ? '179px' : null)};
  z-index: 10;
  align-items: center;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`
const InputCalendarControl = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`
const StyledInput = styled(Input)`
  &&& {
    width: 100%;
    max-height: 38px;
    input::placeholder {
      color: var(--asc-sonicsilver);
      opacity: 0.7;
    }
  }
`

const StyledFormField = styled(Form.Field)`
  &&& {
    margin-bottom: 0;
    position: relative;
  }
`

const TodayButton = styled(Button)`
  &&& {
    background: var(--secondary);
    color: var(--primary);
    :hover {
      background: var(--primary);
      color: var(--secondary);
    }
  }
`
const CloseButton = styled(Button)`
  &&& {
    background: transparent;
    color: var(--primary);
    box-shadow: none;
    :hover {
      background-color: var(--asc-platinum);
      color: var(--primary);
    }
  }
`

type Props = {
  name: string
  label: string
  defaultValue?: string
  maxDate?: Date
  minDate?: Date
  setValue: (name: string, value: Date | null) => void
  required?: boolean
  disabled?: boolean
  disabledDates?: Date[]
  value: string
  calendarPosition?: string
  width?: string
  prefix?: string
  errors?: ErrorType
  placeholder?: string | null
  className?: string
  showTodayButton?: boolean
}

const DateInput = ({
  name,
  label,
  defaultValue = '',
  maxDate,
  minDate,
  setValue,
  required = false,
  disabled,
  disabledDates = [],
  value,
  calendarPosition,
  width = '100%',
  prefix,
  errors,
  placeholder = null,
  className,
  showTodayButton = true,
}: Props) => {
  const {ref, isComponentVisible, setIsComponentVisible} =
    useComponentVisible(false)
  const userConfig = useStore(state => state.userConfig)
  const dateValue =
    userConfig && value && parse(value, userConfig.Date_Format, new Date())
  const dateFormat = userConfig?.Date_Format || 'MM/dd/yyyy'
  const today = format(new Date(), dateFormat)
  const [currentMinDate, setCurrentMinDate] = React.useState(minDate)
  const [currentMaxDate, setCurrentMaxDate] = React.useState(maxDate)
  const [todayDate, setTodayDate] = React.useState(new Date())

  //if the date range picker is rendered and the date today changes, add one day to the min/max date if it exists
  React.useEffect(() => {
    const updateMaxDate = () => {
      const currentTime = new Date()
      // Check if the current date and time is after the end of today
      if (isAfter(currentTime, endOfDay(todayDate))) {
        setTodayDate(currentTime)
        const newMaxDate = maxDate ? addDays(maxDate, 1) : maxDate
        const newMinDate = minDate ? subDays(minDate, 1) : minDate
        setCurrentMaxDate(newMaxDate)
        setCurrentMinDate(newMinDate)
      }
    }
    const intervalId = setInterval(updateMaxDate, 60000)
    return () => {
      clearInterval(intervalId)
    }
  }, [maxDate, minDate, todayDate])

  const selectedDate: Date | undefined = value
    ? dateValue || undefined
    : defaultValue
    ? new Date(defaultValue)
    : undefined

  return (
    <Container ref={ref} width={width} className={className}>
      <StyledFormField>
        <StyledLabel htmlFor={`date-input-${label}`}>
          {label} {required && <RequiredAsterisk />}
        </StyledLabel>
        <InputCalendarControl>
          <StyledInput labelPosition="right" id={`date-input-${label}`}>
            {prefix && <Label>{prefix}</Label>}
            <input
              disabled={disabled}
              data-cy="date-input"
              value={value === today ? 'Today' : value ? value : ''}
              name={name}
              autoComplete="off"
              placeholder={placeholder || ''}
              onClick={() => {
                if (!isComponentVisible) {
                  setIsComponentVisible(true)
                }
              }}
              // ref={inputRef}
            />
            <StyledIconLabel
              data-cy="date-input-calendar-label"
              onClick={() => {
                if (!isComponentVisible && !disabled) {
                  setIsComponentVisible(true)
                }
              }}
              disabled={disabled}
            >
              <CalendarBlank size={20} weight={'duotone'} />
            </StyledIconLabel>
          </StyledInput>
          {errors && <InputErrorMessage message={errors[name]?.message} />}
        </InputCalendarControl>
      </StyledFormField>
      {isComponentVisible && (
        <CalendarContainer
          data-cy="date-input-calendar"
          calendarPosition={calendarPosition || ''}
        >
          <DatePicker
            data-cy="date-picker"
            selectedDate={selectedDate}
            maxDate={currentMaxDate ? new Date(currentMaxDate) : undefined}
            minDate={currentMinDate ? new Date(currentMinDate) : undefined}
            onChange={date => {
              setValue(name, date) //don't format this date.
              setIsComponentVisible(false)
            }}
            disabledDates={disabledDates}
          />
          <ButtonContainer>
            <Button.Group>
              {showTodayButton && (
                <TodayButton
                  compact
                  type="button"
                  color="blue"
                  data-cy="date-input-today"
                  onClick={() => {
                    setValue(name, new Date()) //don't format this date.
                    setIsComponentVisible(false)
                  }}
                >
                  Today
                </TodayButton>
              )}
              {!required && (
                <Button
                  compact
                  type="button"
                  color="red"
                  data-cy="date-input-clear"
                  onClick={() => {
                    setValue(name, null)
                    setIsComponentVisible(false)
                  }}
                >
                  Clear
                </Button>
              )}
            </Button.Group>
            <CloseButton
              compact
              type="button"
              color="green"
              data-cy="date-input-close"
              onClick={() => setIsComponentVisible(false)}
            >
              <Icon name="remove" />
              Close
            </CloseButton>
          </ButtonContainer>
        </CalendarContainer>
      )}
    </Container>
  )
}

export default DateInput
