import * as React from 'react'
import useCameraDataUsage from './hooks/useCameraDataUsage'
import Modal from 'common/Modal'
import styled from 'styled-components/macro'
import {Column, ProgressBar, Row, Spinner, Typography} from 'common/components'

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: center;
`
const Container = styled(Column)`
  width: fit-content;
  height: 100%;
  padding: 20px;
  gap: 15px;
`
const ProgressRow = styled(Row)`
  gap: 10px;
  align-items: center;
`
const ProgressContent = styled.div`
  width: 120px;
`
const StyledProgressBar = styled(ProgressBar)`
  font-size: calc(12 / 16 * 1rem);
  font-weight: 700;
`
const StyledZeroProgressBar = styled(StyledProgressBar)`
  .bar {
    width: 0;
    min-width: 0;
  }
`
const NoteContainer = styled.div`
  background-color: var(--asc-floralwhite);
  border: solid 1px var(--asc-ginfizz);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
`

const BoldText = styled.b`
  margin: 0px 3px;
`
const CameraUsageDetail = ({
  idAsset,
  showDialog,
  assetLabel,
  onDismiss,
}: {
  idAsset: number
  showDialog: boolean
  assetLabel: string
  onDismiss: () => void
}) => {
  const videoEventsPerDay = 5
  const videoEventsPerMonth = 150
  const minutesOfLiveViewPerMonth = 15
  const {data: cameraUsage, status: cameraUsageStatus} =
    useCameraDataUsage(idAsset)
  const videoEventsPerDayValue = Math.ceil(cameraUsage?.videoEventsToday || 0)
  const videoEventsPerMonthValue = Math.ceil(cameraUsage?.videoEventsMonth || 0)
  const minutesOfLiveViewPerMonthValue = Math.ceil(
    cameraUsage?.liveStreamViewingMinutes || 0,
  )
  const videoEventsPerDayPercent = Math.ceil(
    (videoEventsPerDayValue / videoEventsPerDay) * 100,
  )

  const videoEventsPerMonthPercent = Math.ceil(
    (videoEventsPerMonthValue / videoEventsPerMonth) * 100,
  )

  const minutesOfLiveViewPerMonthPercent = Math.ceil(
    (minutesOfLiveViewPerMonthValue / minutesOfLiveViewPerMonth) * 100,
  )
  return (
    <Modal
      showDialog={showDialog}
      title={'Surfsight Camera Usage Limits'}
      onDismiss={onDismiss}
      width="800px"
    >
      <Wrapper>
        <Container>
          <Typography fontSize={20}>{`${assetLabel}`}</Typography>
          {cameraUsageStatus === 'loading' ? (
            <Spinner type="partial" size="30px" />
          ) : (
            <>
              <ProgressRow>
                <ProgressContent>
                  {videoEventsPerDayPercent > 0 ? (
                    <StyledProgressBar
                      color={'var(--secondary)'}
                      value={videoEventsPerDayPercent}
                      fontColor="black"
                    />
                  ) : (
                    <StyledZeroProgressBar
                      color={'var(--secondary)'}
                      value={videoEventsPerDayPercent}
                      fontColor="black"
                    />
                  )}
                </ProgressContent>
                <Typography fontSize={16}>Video Events Per Day</Typography>
                <Typography
                  fontSize={16}
                  fontWeight="normal"
                >{`${videoEventsPerDayValue} of ${videoEventsPerDay}`}</Typography>
              </ProgressRow>
              <ProgressRow>
                <ProgressContent>
                  {videoEventsPerMonthPercent > 0 ? (
                    <StyledProgressBar
                      color={'var(--secondary)'}
                      value={videoEventsPerMonthPercent}
                      fontColor="black"
                    />
                  ) : (
                    <StyledZeroProgressBar
                      color={'var(--secondary)'}
                      value={videoEventsPerMonthPercent}
                      fontColor="black"
                    />
                  )}
                </ProgressContent>
                <Typography fontSize={16}>Video Events Per Month*</Typography>
                <Typography
                  fontSize={16}
                  fontWeight="normal"
                >{`${videoEventsPerMonthValue} of ${videoEventsPerMonth}`}</Typography>
              </ProgressRow>
              <ProgressRow>
                <ProgressContent>
                  {minutesOfLiveViewPerMonthPercent > 0 ? (
                    <StyledProgressBar
                      color={'var(--secondary)'}
                      value={minutesOfLiveViewPerMonthPercent}
                      fontColor="black"
                    />
                  ) : (
                    <StyledZeroProgressBar
                      color={'var(--secondary)'}
                      value={minutesOfLiveViewPerMonthPercent}
                      fontColor="black"
                    />
                  )}
                </ProgressContent>
                <Typography fontSize={16}>
                  Minutes of Live View Per Month*
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight="normal"
                >{`${minutesOfLiveViewPerMonthValue} of ${minutesOfLiveViewPerMonth}`}</Typography>
              </ProgressRow>
              <NoteContainer>
                *<BoldText>NOTE:</BoldText> Monthly Limits reset at the
                beginning of each month
              </NoteContainer>
            </>
          )}
        </Container>
      </Wrapper>
    </Modal>
  )
}
export default CameraUsageDetail
