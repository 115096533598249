import * as React from 'react'
import styled from 'styled-components/macro'
import {Button} from 'semantic-ui-react'
import {Envelope, Phone, NoteBlank, MapPin, Link} from 'phosphor-react'
import {
  SubmitValues,
  PostTripDataProps,
} from 'tripshare/TripShareModal/tripShareTypes'
import Modal from 'common/Modal'
import {ascendColors} from 'common/GlobalStyles'

type Props = {
  setShowConfirmModal: (showModal: boolean) => void
  showConfirmModal: boolean
  asset: string
  postTripData: PostTripDataProps
  submitValues: SubmitValues | undefined
}

const Container = styled.div`
  border-top: solid 1px var(--asc-coolgray);
  border-bottom: solid 1px var(--asc-coolgray);
`

const Title = styled.div`
  display: flex;
  color: var(--Black, #000);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-top: 30px;
  margin-left: 33px;
  margin-bottom: 26px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 50px;
  margin-bottom: 47px;
`
const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 38px;
  width: 50%;
`

const InfoIcon = styled.div`
  display: flex;
  min-width: 20px;
  margin-right: 10px;
`

const InfoText = styled.div`
  display: flex;
  margin-bottom: 13px;
`

const StyledModal = styled(Modal)`
  h2 {
    width: 100%;
  }
`

const Footer = styled.div`
  padding: 30px;
  display: flex;
`

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    width: 100%;
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
  }
`

const AssetLabel = styled.div`
  color: var(--asc-dimgray);
  text-align: right;

  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  align-items: center;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const CopyButton = styled(Button)<{isSelected: boolean}>`
  &&& {
    display: flex;
    align-items: center;
    background-color: ${props =>
      props.isSelected ? 'var(--primary)' : 'var(--secondary)'};
    color: ${props =>
      props.isSelected
        ? ascendColors.fontOnPrimary
        : ascendColors.fontOnSecondary};
    font-weight: 900;
  }
  svg {
    margin-right: 8px;
  }
  :hover {
    background-color: ${props =>
      props.isSelected ? 'var(--secondary)' : 'var(--primary)'};
    color: ${props =>
      props.isSelected
        ? ascendColors.fontOnSecondary
        : ascendColors.fontOnPrimary};
  }
`

const LinkText = styled.div`
  display: flex;
  margin-right: 44px;
  align-items: center;

  color: var(--Black);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
  width: 100%;
`

const TripShareModalConfirmation = ({
  setShowConfirmModal,
  showConfirmModal = false,
  asset,
  submitValues,
  postTripData,
}: Props) => {
  const [linkCopied, setLinkCopied] = React.useState(false)
  function handleCopy() {
    navigator.clipboard.writeText(postTripData?.Url)
    setLinkCopied(true)
  }

  function handleCancel() {
    setShowConfirmModal(false)
  }

  const hasShowContent =
    submitValues?.destinationAddress ||
    submitValues?.message ||
    submitValues?.emailAddresses ||
    submitValues?.smsNumbers

  return (
    <StyledModal
      title={
        <ModalTitle>
          <div>Link successfully sent!</div>
          <AssetLabel>{asset}</AssetLabel>
        </ModalTitle>
      }
      onDismiss={handleCancel}
      onSave={() => {}}
      showDialog={showConfirmModal}
      width="892"
    >
      {hasShowContent && (
        <Container>
          <Title>Shared Info</Title>

          <InfoContainer>
            {(submitValues?.destinationAddress || submitValues?.message) && (
              <InfoColumn>
                {submitValues?.destinationAddress && (
                  <InfoText>
                    <InfoIcon>
                      <MapPin color="var(--asc-graphite)" size={20} />
                    </InfoIcon>
                    {submitValues?.destinationAddress}
                  </InfoText>
                )}
                {submitValues?.message && (
                  <InfoText>
                    <InfoIcon>
                      <NoteBlank color="var(--asc-graphite)" size={20} />
                    </InfoIcon>
                    {submitValues?.message}
                  </InfoText>
                )}
              </InfoColumn>
            )}
            {(submitValues?.emailAddresses || submitValues?.smsNumbers) && (
              <InfoColumn>
                {submitValues?.emailAddresses && (
                  <InfoText>
                    <InfoIcon>
                      <Envelope color="var(--asc-graphite)" size={20} />
                    </InfoIcon>
                    {submitValues?.emailAddresses}
                  </InfoText>
                )}
                {submitValues?.smsNumbers && (
                  <InfoText>
                    <InfoIcon>
                      <Phone color="var(--asc-moss)" size={20} />
                    </InfoIcon>
                    {submitValues?.smsNumbers}
                  </InfoText>
                )}
              </InfoColumn>
            )}
          </InfoContainer>
        </Container>
      )}
      {!hasShowContent && (
        <Container>
          <Title>Asset Location Only</Title>
        </Container>
      )}
      <Footer>
        {postTripData?.Url && <LinkText>{postTripData?.Url}</LinkText>}

        <ButtonContainer>
          <CopyButton
            onClick={handleCopy}
            isSelected={linkCopied}
            disabled={!postTripData?.Url}
          >
            <Link color={ascendColors.fontOnSecondary} size={20} />
            {linkCopied ? 'Link Copied' : 'Copy Link'}
          </CopyButton>
        </ButtonContainer>
      </Footer>
    </StyledModal>
  )
}

export default TripShareModalConfirmation
