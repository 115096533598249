import {ColumnsData} from './hooks/useColumns'
import {SchemaType} from './SubmitPanel'

export const initialAccountCustomizeViews = {
  changedColumns: {},
  activeIndex: 0,
  errors: {},
  isDirty: false,
}
type ColumnType = Record<string, {name: string; items: ItemType[]}>
export type CustomizeViewsState = {
  changedColumns: {
    gridColumns?: boolean
    popupColumns?: boolean
    dashboardColumns?: boolean
  }
  dashboardColumns?: ColumnType
  gridColumns?: ColumnType
  popupColumns?: ColumnType
  activeIndex: number
  errors: {
    [key: string]: string
  }
  isDirty: boolean
  loading?: boolean
  popupSchema?: SchemaType[]
  gridSchema?: SchemaType[]
  dashboardSchema?: SchemaType[]
}
type ItemType = {
  UOM?: string
  idSchema: number
  name: string
  deviceFieldName?: string
  FieldMapping: string
  type: string
  columnheader: string
  schematype: string
  pinned: number
  width: number
  cellsalign: string
  column: number
  cellclassname: string
  precision: number
  latflag: number
  longflag: number
  tabgroup: number
  columnfilterable: number
  columnsortable: number
  columnmenuvisible: number
  gaugemax?: number
  gaugemin?: number
  gaugelowerActionLimit?: number
  gaugeupperActionLimit?: number
  gaugelowerWarningLimit?: number
  gaugeupperWarningLimit?: number
  gaugeactionRangeColor?: string
  gaugeoptimumRangeColor?: string
  gaugewarningRangeColor?: string
  gaugeUnit?: string
  gaugeTitle?: string
  FieldSeq: number
  isTimeSeries: boolean
  idCustomerField: number
  fieldVisibility: number
  UnitType?: number | undefined
  FieldPresentationHeader?: string
}

type ResultType = {
  [key: string]: {
    name?: string
    items: ItemType[]
  }
}
type ActionTypes =
  | {type: 'setState'; data: Partial<CustomizeViewsState>}
  | {type: 'setDirty'; data: boolean}
  | {type: 'initialColumns'; data: Partial<CustomizeViewsState>}
  //newColumns is always undefined but it is required to be passed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | {type: 'customHeader'; data: {newColumns: any; changedColumn: string}}
  | {type: 'setColumns'; data: {name: string; result: ResultType}}
  | {type: 'clearState'}

export const accountCustomizeViewsReducer = (
  state: CustomizeViewsState = initialAccountCustomizeViews,
  action: ActionTypes,
) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'setDirty': {
      return {
        ...state,
        isDirty: action.data,
      }
    }
    case 'initialColumns': {
      return {
        ...state,
        ...action.data,
        isDirty: false,
      }
    }
    case 'customHeader': {
      return {
        ...state,
        ...action.data.newColumns,
        isDirty: true,
        changedColumns: {
          ...state.changedColumns,
          [action.data.changedColumn]: true,
        },
      }
    }
    case 'setColumns': {
      return {
        ...state,
        changedColumns: {...state.changedColumns, [action.data.name]: true},
        [action.data.name]: action.data.result,
        isDirty: true,
      }
    }
    case 'clearState': {
      return {...initialAccountCustomizeViews}
    }
    default:
      console.error('Unrecognized action type', action)
      return state
  }
}

type OnDragEndParams = {
  result: {
    destination?: {droppableId: string; index: number} | null
    source: {droppableId: string; index: number}
  }
  name: string
  dispatch: React.Dispatch<ActionTypes>
  columns: ColumnType
}
export const onDragEnd = ({
  result,
  name,
  dispatch,
  columns,
}: OnDragEndParams) => {
  if (!result?.destination) return
  const {source, destination} = result
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    dispatch({
      type: 'setColumns',
      data: {
        name,
        result: {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        },
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    dispatch({
      type: 'setColumns',
      data: {
        name,
        result: {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        },
      },
    })
  }
}

export const submitGrid = (
  columns: {items: ItemType[]}[],
  schemaname: string,
  schema: SchemaType[],
): Partial<SchemaType>[] => {
  const SchemaTypes: Partial<SchemaType>[] = columns[0].items.map(item => {
    return {
      FieldPresentationColumn: 0,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  // add back Asset Name since it can't be removed
  SchemaTypes.splice(0, 0, {
    FieldPresentationColumn: 0,
    FieldPresentationHeader: schema[0].columnheader,
    FieldPresentationType: schemaname,
    idCustomerFieldPresentation: schema[0].idSchema,
  })
  SchemaTypes.join()
  return SchemaTypes
}

export const submitPopup = (
  columns: {items: SchemaType[]}[],
  schemaname: string,
  schema: SchemaType[],
): Partial<SchemaType>[] => {
  const SchemaTypes: Partial<SchemaType>[] = columns[0].items.map(item => {
    return {
      FieldPresentationColumn: 0,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  // add back removed items (removed because they can't be modified)
  const unmodifiedSchema: Partial<SchemaType>[] = schema
    .slice(0, 5)
    .map(item => {
      const returnItem: Partial<SchemaType> = {
        FieldPresentationColumn: 0,
        FieldPresentationType: schemaname,
        FieldPresentationHeader: item.columnheader,
        idCustomerFieldPresentation: item.idSchema,
      }
      if (item.columnheader) {
        returnItem.FieldPresentationHeader = item.columnheader
      }
      return returnItem
    })

  return [...unmodifiedSchema, ...SchemaTypes]
}
export const submitDashboard = (
  columns: {items: SchemaType[]}[],
  schemaname: string,
) => {
  const SchemaType0 = columns[0].items.map(item => {
    return {
      FieldPresentationColumn: 0,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  const SchemaType1 = columns[1].items.map(item => {
    return {
      FieldPresentationColumn: 1,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  return [...SchemaType0, ...SchemaType1]
}

type OnSubmitProps = {
  gridColumns: {items: SchemaType[]}[]
  popupColumns: {items: SchemaType[]}[]
  dashboardColumns: {items: SchemaType[]}[]
  putCustomizeViews: (submitObject: SubmitSchemaObject) => Promise<void>
  changedColumns: {
    gridColumns?: boolean
    popupColumns?: boolean
    dashboardColumns?: boolean
  }
  data?: Partial<ColumnsData>
}

export type SubmitSchemaObject = {
  grid?: ItemType[]
  mobile_popup?: SchemaType[]
  asset_detail?: SchemaType[]
}

export const onSubmit = async ({
  gridColumns,
  popupColumns,
  dashboardColumns,
  putCustomizeViews,
  changedColumns,
  data,
}: OnSubmitProps) => {
  const submitObject: SubmitSchemaObject = {}

  if (changedColumns.gridColumns && data?.gridSchema) {
    submitObject.grid = submitGrid(
      gridColumns,
      'grid',
      data.gridSchema,
    ) as SchemaType[]
  }
  if (changedColumns.popupColumns && data?.popupSchema) {
    submitObject.mobile_popup = submitPopup(
      popupColumns,
      'mobile_popup',
      data.popupSchema,
    ) as SchemaType[]
  }
  if (changedColumns.dashboardColumns) {
    submitObject.asset_detail = submitDashboard(
      dashboardColumns,
      'asset_detail',
    ) as SchemaType[]
  }
  if (
    changedColumns.gridColumns ||
    changedColumns.popupColumns ||
    changedColumns.dashboardColumns
  ) {
    await putCustomizeViews(submitObject)
  }
}

type FindAndChangeCustomHeaderParams = {
  columns: ColumnType
  columnId: keyof CustomizeViewsState['changedColumns']
  index: number
  customName: string
}
const findAndChangeCustomHeader = ({
  columns,
  columnId,
  index,
  customName,
}: FindAndChangeCustomHeaderParams) => {
  const changedColumn = columns[columnId]

  changedColumn.items.map((column, thisIndex) => {
    if (thisIndex === index) {
      column.FieldPresentationHeader = customName
      column.columnheader = customName
    }
    return column
  })
  columns[columnId] = changedColumn
  return columns
}

type OnCustomNameChangeApplyParams = {
  name: string
  columns: {
    gridColumns: ColumnType
    dashboardColumns: ColumnType
    popupColumns: ColumnType
  }
  columnId: keyof CustomizeViewsState['changedColumns']
  index: number
  customName: string
  dispatch: React.Dispatch<ActionTypes>
  setOpenIndex: React.Dispatch<React.SetStateAction<number | null>>
}

export const onCustomNameChangeApply = ({
  name,
  columns,
  columnId,
  index,
  customName,
  dispatch,
  setOpenIndex,
}: OnCustomNameChangeApplyParams) => {
  let newColumns
  let changedColumn
  if (name === 'Asset Grid' && columns.gridColumns) {
    changedColumn = 'gridColumns'
    columns.gridColumns = findAndChangeCustomHeader({
      columns: columns.gridColumns,
      columnId,
      index,
      customName,
    })
  } else if (name === 'Asset Pop-up') {
    changedColumn = 'popupColumns'
    columns.popupColumns = findAndChangeCustomHeader({
      columns: columns.popupColumns,
      columnId,
      index,
      customName,
    })
  } else {
    changedColumn = 'dashboardColumns'
    columns.dashboardColumns = findAndChangeCustomHeader({
      columns: columns.dashboardColumns,
      columnId,
      index,
      customName,
    })
  }

  dispatch({
    type: 'customHeader',
    data: {
      newColumns,
      changedColumn,
    },
  })
  setOpenIndex(null)
}

export const getPopUpColumn = (schema: SchemaType[]) => {
  const notToRender = [
    'Asset_Label',
    'Last_Heading_Cardinal',
    'In_Motion',
    'Asset_Icon_Full_Path',
    'Last_Nearby_Address',
    'Last_Location_DateTime_Local',
    'Last_Event',
  ]
  const filteredFieldVisibiltySchema = schema?.filter(
    item =>
      item.fieldVisibility &&
      notToRender.indexOf(item.deviceFieldName || '') === -1 &&
      item.columnheader !== 'Heading',
  )

  const availableSchema = schema?.filter(
    item =>
      !item.fieldVisibility &&
      notToRender.indexOf(item.deviceFieldName || '') === -1 &&
      item.columnheader !== 'Heading',
  )
  return {
    ['0']: {
      name: 'Asset Pop-up',
      items: filteredFieldVisibiltySchema || [],
    },
    ['1']: {
      name: 'Available Data Fields',
      items: availableSchema || [],
    },
  }
}
