import * as React from 'react'
import styled from 'styled-components/macro'
import {debounce} from 'lodash'
import {QUERIES} from 'common/constants'
import {applyGlobalFilter} from 'grid/gridTableHelpers'
import {useGridFilterStore} from './useGridFilterStore'
import {addLightnessToHexColor} from 'common/helpers'
import {ascendColors} from 'common/GlobalStyles'
import {XCircle} from 'phosphor-react'

const Wrapper = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  @media ${QUERIES.tabletAndUp} {
    flex: inherit;
    height: 100%;
  }
  max-width: 400px;
`

const Input = styled.input`
  border: 1px solid var(--asc-graphite);
  border-radius: 7px;
  padding: 3px 10px;
  display: flex;
  @media ${QUERIES.tabletAndUp} {
    width: 300px;
  }
  :focus-visible {
    border: 1px solid transparent;
    outline: 1px solid var(--tertiary) !important;
    box-shadow: 0 0px 5px 0 var(--asc-coolgray);
  }
`

type Props = {
  totalAssetCount: number
  assetFilter: {label: string; filteredAssetIDs: number[]} | undefined
  clearFunction: () => void
}

const FilterPill = styled.div<{customTertiary: string}>`
  display: flex;
  align-items: center;
  background-color: ${p => p.customTertiary};
  position: absolute;
  top: 5px;
  right: 4px;
  color: var(--black);
  border-radius: 20px;
  padding: 2px 5px 2px 7px;
  font-size: 12px;
  line-height: 12px;
  gap: 5px;
`
const StyledXCircle = styled(XCircle)`
  cursor: pointer;
`

const FilterPillTitle = styled.span`
  font-weight: 700;
`

const FilterLabel = styled.span`
  max-width: 100px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
`
const ClearButton = styled.button`
  color: var(--primary);
  font-weight: 700;
  font-size: 13px;
  padding: 0 10px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 2px;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: 1px solid var(--asc-cultured);
    text-decoration: underline;
  }
  :after {
    content: 'Clear';
  }
`

const GlobalFilter = ({totalAssetCount, assetFilter, clearFunction}: Props) => {
  const globalFilter = useGridFilterStore(state => state.globalFilter)
  const columnFilters = useGridFilterStore(state => state.columnFilters)
  const setGlobalFilter = useGridFilterStore(state => state.setGlobalFilter)
  const onChange = debounce((value: string) => {
    const currentFilters = {
      globalFilter,
      columnFilters,
    }
    applyGlobalFilter({value, filters: currentFilters})
  }, 100)

  return (
    <>
      <Wrapper>
        <Input
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value)
            onChange(e.target.value)
          }}
          placeholder={`Search ${totalAssetCount} records...`}
          data-lpignore="true"
          data-testid="assetgrid-search"
        />
        {assetFilter && (
          <FilterPill
            customTertiary={addLightnessToHexColor(
              ascendColors.customTertiary,
              50,
            )}
          >
            <FilterPillTitle>Filter:</FilterPillTitle>
            <FilterLabel> {assetFilter.label}</FilterLabel>
            <StyledXCircle
              size={16}
              weight={'fill'}
              color={'var(--black)'}
              onClick={clearFunction}
              data-cy={'clear-filter'}
            />
          </FilterPill>
        )}
      </Wrapper>
      {globalFilter?.length ? (
        <ClearButton
          data-cy={'clear-button'}
          onClick={clearFunction}
          data-testid="search-clear"
        />
      ) : null}
    </>
  )
}

export default GlobalFilter
