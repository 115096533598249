import {ascendColors} from 'common/GlobalStyles'
import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div<{disabled: boolean}>`
  position: relative;
  display: inline-block;
  width: 64px;
  height: 28px;
  cursor: ${({disabled}: {disabled: boolean}) =>
    disabled ? 'default' : 'pointer'};
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + .slider {
    background-color: var(--secondary);
    :before {
      left: 14px;
    }
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(--asc-concrete);
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`
const Slider = styled.span`
  border-radius: 34px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* -webkit-transition: 0.4s;
  transition: 0.4s; */
  :before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    /* -webkit-transition: 0.4s;
    transition: 0.4s; */
  }
`
const Label = styled.span<{fontOnSecondary: string; checked: boolean}>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  position: absolute;
  color: ${({fontOnSecondary}) => fontOnSecondary};
  left: ${({checked}) => (checked ? '10px' : '31px')};
  bottom: 4px;
  user-select: none;
`
type ToggleProps = {
  checked: boolean
  onChange: (
    event: React.FormEvent<HTMLInputElement>,
    data: {checked: boolean},
  ) => void
  className?: string
  customOn?: string
  customOff?: string
  disabled?: boolean
}
const Toggle = ({
  onChange,
  checked,
  className = '',
  customOn = '',
  customOff = '',
  disabled = false,
}: ToggleProps) => {
  const inputRef = React.useRef(null)
  const clickInput = () => {
    const input = inputRef.current as HTMLInputElement | null
    if (input?.click) {
      input.click()
    }
  }

  const getLabelValue = () => {
    if (checked) {
      if (customOn && customOn !== '') {
        return customOn
      } else {
        return 'YES'
      }
    } else {
      if (customOff && customOff !== '') {
        return customOff
      } else {
        return 'NO'
      }
    }
  }
  return (
    <Container
      className={className}
      onClick={clickInput}
      data-cy="toggle-button"
      disabled={disabled}
    >
      <input
        data-cy="checkbox"
        ref={inputRef}
        type="checkbox"
        onChange={e => onChange(e, {checked: e.currentTarget.checked})}
        checked={checked}
        disabled={disabled}
      />
      <Slider className="slider" data-cy="slider" />
      <Label fontOnSecondary={ascendColors.fontOnSecondary} checked={checked}>
        {getLabelValue()}
      </Label>
    </Container>
  )
}
export default Toggle
