import Swal from 'sweetalert2'
import {format, subSeconds} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import styled from 'styled-components/macro'
import {UserConfig} from 'common/useStore'
import {Select} from 'common/components'
import {Message} from 'semantic-ui-react'
import {RequestedCameraType} from './CameraEventRequestModal'

//const timeout = 175000 //175000 = 2 minutes 55 seconds -- just under the three minute timeout from SW

// export type CameraAssetType = {
//   Asset_Description: string
//   Asset_Label: string
//   CurrentDriver: string
//   CurrentIdDriver: number
//   Last_Communication: string
//   Serial_Number_Displayed: string
//   idAsset: number
//   idDevice: number
//   idDevice_Type: number
//   DeviceModelIDDV?: string
// }

export type CameraListItemType = {
  value: number | ''
  asset: RequestedCameraType
  index: number
  label: string
}
export type CameraRequestStateType = {
  showDialog: boolean
  showCustomDate: boolean
  maxDate: Date
  customDateInput: Date
  showHyperlapseOptions: boolean
  subSamplingSelected: number
  selectedAsset: RequestedCameraType | null
  showSurfsight: boolean
  cameraList:
    | {
        value: number | ''
        asset: RequestedCameraType
        index: number
        label: string
      }[]
    | null
  submitting: boolean
  hyperLapse: boolean
  idDevice_Type?: number
  slider: {
    min: number
    max: number
    value: number
  }
}

export const cameraRequestState: CameraRequestStateType = {
  showDialog: false,
  showCustomDate: false,
  maxDate: new Date(),
  customDateInput: new Date(),
  showHyperlapseOptions: false,
  subSamplingSelected: 10,
  selectedAsset: null,
  showSurfsight: false,
  cameraList: null,
  submitting: false,
  hyperLapse: false,
  slider: {
    min: 20,
    max: 20,
    value: 20,
  },
}
export const getInitialState = (
  requestedCamera: RequestedCameraType | null,
) => {
  return {
    showDialog: false,
    showCustomDate: false,
    maxDate: new Date(),
    customDateInput: new Date(),
    showHyperlapseOptions: false,
    subSamplingSelected: 10,
    selectedAsset: requestedCamera,
    showSurfsight: false,
    cameraList: null,
    submitting: false,
    hyperLapse: false,
    slider: {
      min: 20,
      max: 20,
      value: 20,
      options: {
        floor: 10,
        ceil: 20,
        step: 1,
        disabled: false,
      },
    },
  }
}
export const secondsToMinutes = (seconds: number) => {
  var minutes = (seconds / 60).toFixed(1)
  return minutes || 0
}

type ActionType = {
  type: 'setState'
  data: Partial<CameraRequestStateType>
}

export const cameraRequestReducer = (
  state = cameraRequestState,
  action: ActionType,
) => {
  switch (action.type) {
    case 'setState': {
      const newUpdate = {
        ...state,
        ...action.data,
      }
      return newUpdate
    }
    default:
      return state
  }
}
export const getVideoURL = (
  state: CameraRequestStateType,
  closeModal: () => void,
  userConfig?: UserConfig,
) => {
  var url
  const requesttime = `${
    state.slider.value > 0
      ? `&SecondsOfVideo=${state.slider.value}&Quality=high`
      : ''
  }`
  if (state.showCustomDate && userConfig) {
    const newCustomDate = new Date(state.customDateInput)
    const zonedNow = toZonedTime(new Date(), userConfig.TZ_Moment_Name)
    if (newCustomDate > zonedNow) {
      Swal.fire(
        'Invalid time',
        'Please choose a time in the past to retrieve the camera event',
        'error',
      )
      return false
    }
    var datePickerSubmitFormat = format(newCustomDate, 'MM/dd/yyyy HH:mm:ss')
    url =
      '/camera/GetVideoURL/' +
      state.selectedAsset?.idAsset +
      '?FromTime=' +
      datePickerSubmitFormat +
      requesttime
  } else {
    //get latest video -- don't use SmartWitness API 2.0 getlatestvideo because URLs expire in 20 minutes
    url =
      '/camera/GetVideoURL/' +
      state.selectedAsset?.idAsset +
      '?FromTime=' +
      format(subSeconds(new Date(), 20), 'MM/dd/yyyy HH:mm:ss') +
      requesttime
  }
  //adding SubSampleingInterval if hyperLapse is enabled
  if (state.hyperLapse) {
    url = `${url}&SubSamplingInterval=${state.subSamplingSelected}`
  }
  return url
}

export const Container = styled.div<{
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  display: flex;
  padding: 0 10px;
  button {
    text-transform: uppercase;
    background: var(--secondary);
    color: ${p => p.fontOnSecondary};
    font-weight: bold;
    font-size: 13px;
    height: 40px;
    :hover {
      color: ${p => p.fontOnPrimary};
      background: var(--primary);
    }
  }
`

export const RadioGroup = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 300px;
`
export const NoCameraOnLine = styled.div`
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5bc0de;
  margin-bottom: 20px;
`
export const Options = styled.div`
  padding: 10px 0;
  margin: 0 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Paragraph = styled.div`
  width: 100%;
  /* padding-bottom: 10px; */
`
export const TimeContent = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`

export const LoadingCamerasText = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  color: var(--grey-800);
  align-items: center;
  width: 500px;
`

// export const RenderTrack = styled.div`
//   height: 10px;
//   margin: 0 0px;
//   background-color: #d8e0f3;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   .slider-track-1 {
//     background: #d8e0f3;
//   }
// `
// export const RenderThumb = styled.div`
//   top: -15px;
//   width: 25px;
//   height: 25px;
//   justify-content: center;
//   font-size: 0.8em;
//   background-color: ${(props: {disabled: boolean}) =>
//     props.disabled ? '#ccc' : '#0db9f0'};
//   color: white;
//   cursor: pointer;
//   border: ${props => (props.disabled ? '5px solid #ccc' : '5px solid #0db9f0')};
//   cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
//   box-sizing: content-box;
//   text-align: center;
//   border-radius: 50%;
//   justify-content: center;
//   align-items: center;
//   display: flex;
// `
export const SliderComponent = styled.div`
  display: block;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 100%;
`
// export const RenderMark = styled.span`
//   margin: 0px calc(25px);
//   bottom: calc(50% - 0px);
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   cursor: pointer;
//   border-radius: 50%;
//   vertical-align: middle;
// `
// export const MarkerText = styled.div`
//   font-size: 0.8rem;
//   padding-top: 40px;
//   margin-left: -15px;
// `
export const HyperLapseContent = styled.div`
  padding: 10px 0;
`
export const SelectContent = styled.div`
  padding: 10px 0;
`
export const TimePickerContainer = styled.div`
  margin: 0px 10px;
  margin-top: 2px;
  input {
    padding: 0.5em 1.5em;
    height: inherit;
    font-size: 14px;
  }
`

export const StyledModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 550px;
`

export const SmartWitnessContainer = styled.div`
  margin: 20px;
`

export const StyledSelect = styled(Select)`
  width: 500px;
  font-size: 18px;
`
export const StyledMessage = styled(Message)`
  &&& {
    border-radius: 8px;
    margin: ${({margin}) => (margin ? margin : 'default')};
  }
`
